<script setup lang="ts">
import { ExclamationIcon, FingerPrintIcon } from '@heroicons/vue/outline';
import type { PropType } from 'vue';
import { avatarGradientStyles } from '~/utilities/avatar-gradient';
import { getUrlForProfile } from '~/utilities/url-slugs';

const props = defineProps({
  profile: Object as PropType<any>,
});
const { profile } = toRefs(props);

const name = computed(() => {
  return profile.value.name || profile.value.encoded_id.substring(0, 16);
});
</script>

<template>
  <div
    class="shadow-card hover:shadow-card-hover group relative flex flex-col divide-y divide-neutral-100 overflow-hidden rounded-lg bg-white transition duration-300 ease-in-out dark:divide-neutral-800 dark:bg-black"
  >
    <div class="aspect-h-1 aspect-w-1 relative overflow-hidden">
      <div v-if="!profile" class="flex items-center justify-center">
        <div class="h-full w-full animate-pulse bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <div v-else-if="profile.verification_state === 2" class="flex items-center justify-center">
        <ExclamationIcon class="h-1/2 w-1/2 text-red-600" />
      </div>
      <img
        v-else-if="profile.avatar_uri"
        :alt="`${name} image`"
        :src="profile.avatar_uri"
        loading="lazy"
        class="h-full w-full object-cover object-center transition duration-300 ease-out betterhover:group-hover:scale-110"
      />
      <div
        v-else
        class="flex items-center justify-center bg-gradient-to-br"
        :style="{ ...avatarGradientStyles(profile.id) }"
      >
        <FingerPrintIcon class="h-1/2 w-1/2 text-neutral-200 dark:text-neutral-700" />
      </div>
      <NuxtLink
        v-if="profile"
        :to="getUrlForProfile(profile)"
        class="absolute inset-0 z-20 flex h-full w-full flex-col items-center justify-start bg-transparent"
      >
        <div class="flex-1"></div>
        <div class="flex w-full px-4 py-4 opacity-0"></div>
      </NuxtLink>
    </div>
    <div class="flex flex-1 flex-col justify-between gap-4 p-4">
      <div v-if="!profile">
        <div class="h-6 w-3/4 rounded-full bg-neutral-100 dark:bg-neutral-800"></div>
      </div>
      <template v-else>
        <div class="flex items-center gap-1">
          <span class="truncate text-xs font-bold text-black dark:text-white">{{ name }}</span>
        </div>
      </template>
    </div>
  </div>
</template>
