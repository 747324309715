<script setup>
import { differenceInMilliseconds, parseISO } from 'date-fns';
import { ArrowRightIcon, StarIcon } from '@heroicons/vue/solid';
import { useLazyFetch } from '#app';
import { getApiRoot } from '~/utilities/api';
import ExploreProfileGrid from '~/components/explore/ExploreProfileGrid.vue';
import HomeMints from '~/components/home/HomeMints.vue';

const { data: featuredContent, pending } = useLazyFetch(`${getApiRoot()}/featured`, { server: false });
// const heroContent = computed(() => featuredContent.value?.find((entry) => entry.type === 0 || entry.type === 1));
const gridCollections = computed(
  () => featuredContent.value?.filter((entry) => entry.type === 4).map((entry) => entry.collection) || []
);

const { data: auctions } = useLazyFetch(`${getApiRoot()}/auctions?size=100`, { server: false });

const auctionNfts = computed(() => {
  return (auctions.value?.items || [])
    .filter(
      (auction) =>
        differenceInMilliseconds(parseISO(auction.start), new Date()) < 1000 * 3600 * 24 &&
        differenceInMilliseconds(parseISO(auction.overtime.end), new Date()) > 0
    )
    .sort(
      (a, b) =>
        differenceInMilliseconds(parseISO(a.overtime.end), new Date()) -
        differenceInMilliseconds(parseISO(b.overtime.end), new Date())
    )
    .slice(0, 4)
    .map((auction) => ({ ...auction.nft, auction }));
});

const { data: mints, pending: mintsPending } = useLazyFetch(`${getApiRoot()}/collections/minting?size=4`, { server: false });

const { data: supporters, pending: supportersPending } = useLazyFetch(`${getApiRoot()}/supporters`, { server: false });

const config = useRuntimeConfig();
</script>

<template>
  <Container>
    <div>
      <div class="pt-12 lg:pt-24">
        <div class="mx-auto max-w-7xl px-6 lg:px-8">
          <div class="mx-auto max-w-3xl text-center">
            <h1 class="text-4xl font-bold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-6xl">
              {{ $t('home.heroTitle') }}
            </h1>
            <p class="mt-6 text-lg leading-8 text-neutral-600 dark:text-neutral-300">
              {{ $t('home.heroSubtitle') }}
            </p>
          </div>
          <!--          <div class="hidden pt-16 2xl:block">-->
          <!--            <ExploreCollectionGrid :collections="gridCollections.slice(0, 4)" :pending="pending" />-->
          <!--          </div>-->
          <!--          <div class="hidden pt-16 lg:block 2xl:hidden">-->
          <!--            <ExploreCollectionGrid :collections="gridCollections.slice(0, 3)" :pending="pending" />-->
          <!--          </div>-->
          <!--          <div class="pt-16 lg:hidden">-->
          <!--            <ExploreCollectionGrid :collections="gridCollections.slice(0, 2)" :pending="pending" />-->
          <!--          </div>-->
        </div>
      </div>
    </div>

    <div class="mx-auto mt-8 flex flex-col items-center lg:mt-16">
      <p class="mt-2 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-4xl">
        Fresh Mints
      </p>
      <p class="mt-2 text-center text-neutral-700 dark:text-neutral-300">
        NFTs are minted dynamically on purchase.<br />
        Create your own edition using our
        <NuxtLink to="/mint" class="font-bold text-emerald-600 dark:text-white">Web Minting</NuxtLink>
        .
      </p>

      <div class="mb-12 text-center"></div>
      <HomeMints :mints="mints?.items" :pending="mintsPending" />
      <div class="flex justify-center pt-8">
        <NuxtLink
          to="/mints"
          class="betterhover:hover:shadow-card-hover flex w-full items-center justify-center rounded-full border border-transparent bg-black px-8 py-3 text-base font-medium text-white transition duration-100 ease-in-out dark:bg-white dark:text-black md:px-10 md:py-4 md:text-lg betterhover:hover:-translate-y-0.5"
        >
          Show all active mints
        </NuxtLink>
      </div>
    </div>

    <div v-if="auctionNfts.length > 0" class="mx-auto mt-8 flex flex-col items-center">
      <p class="mt-2 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-4xl">
        {{ $t('home.activeAuctions') }}
      </p>

      <div class="mb-12 text-center"></div>
      <HomeAuctions :auctions="auctionNfts" />
      <div class="flex justify-center pt-8">
        <NuxtLink
          to="/auctions"
          class="betterhover:hover:shadow-card-hover flex w-full items-center justify-center rounded-full border border-transparent bg-black px-8 py-3 text-base font-medium text-white transition duration-100 ease-in-out dark:bg-white dark:text-black md:px-10 md:py-4 md:text-lg betterhover:hover:-translate-y-0.5"
        >
          Show all auctions
        </NuxtLink>
      </div>
    </div>

    <div class="mx-auto mt-8 flex flex-col items-center lg:mt-16">
      <div class="mb-12 text-center">
        <p class="mt-2 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-4xl">
          {{ $t('home.weeklyTopCollections') }}
        </p>
      </div>
      <HomeTopCollections />
      <div class="flex justify-center pt-8">
        <NuxtLink
          to="/collections?interval=7"
          class="betterhover:hover:shadow-card-hover flex w-full items-center justify-center rounded-full border border-transparent bg-black px-8 py-3 text-base font-medium text-white transition duration-100 ease-in-out dark:bg-white dark:text-black md:px-10 md:py-4 md:text-lg betterhover:hover:-translate-y-0.5"
        >
          {{ $t('home.exploreAllCollections') }}
        </NuxtLink>
      </div>
    </div>

    <div v-if="config.public.network === 'mainnet'" class="mx-auto mt-16 flex flex-col items-center">
      <div class="mb-12 flex flex-col items-center text-center">
        <p
          class="mt-2 flex items-center gap-1 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-4xl"
        >
          <StarIcon class="h-8 w-8 text-amber-500" />
          {{ $t('home.supporters') }}
          <StarIcon class="h-8 w-8 text-amber-500" />
        </p>
        <p class="text-md mt-3 leading-8 text-neutral-600 dark:text-neutral-300">
          Profiles supporting us as
          <NuxtLink class="font-medium text-amber-600 hover:text-amber-700" to="/premium">MintGarden Premium</NuxtLink>
          members
        </p>
      </div>
      <ExploreProfileGrid :profiles="supporters || []" :pending="supportersPending" />
    </div>

    <div class="mx-auto mt-24 flex flex-col items-center">
      <div class="mb-12 text-center">
        <p class="mt-2 text-3xl font-extrabold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-4xl">
          {{ $t('home.weeklyTopCreators') }}
        </p>
      </div>
      <HomeTopArtists />
      <div class="flex justify-center pt-8">
        <NuxtLink
          to="/profiles?interval=7"
          class="betterhover:hover:shadow-card-hover flex w-full items-center justify-center rounded-full border border-transparent bg-black px-8 py-3 text-base font-medium text-white transition duration-100 ease-in-out dark:bg-white dark:text-black md:px-10 md:py-4 md:text-lg betterhover:hover:-translate-y-0.5"
        >
          {{ $t('home.exploreAllCreators') }}
        </NuxtLink>
      </div>
    </div>

    <div class="mx-auto mt-16 flex flex-col items-center">
      <HomeFeatures />
    </div>
  </Container>
</template>
