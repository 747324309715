<script setup lang="ts">
import type { PropType } from 'vue';
import type { Singleton } from '~/types/singleton';

const props = defineProps({
  profiles: Object as PropType<Array<Singleton>>,
  pending: Boolean as PropType<boolean>,
});
const { profiles, pending } = toRefs(props);
</script>

<template>
  <div
    class="grid w-full grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-4 sm:gap-x-6 sm:gap-y-6 lg:grid-cols-6 lg:gap-6 2xl:grid-cols-8 2xl:gap-8"
  >
    <ProfileCard v-for="profile in profiles" :key="profile.id" :profile="profile" />
    <template v-if="pending">
      <ProfileCard v-for="index in 12" :key="index" :profile="undefined" />
    </template>
  </div>
</template>
