<template>
  <div class="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
    <div class="px-6 lg:px-0 lg:pr-4 lg:pt-4">
      <div class="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
        <h2 class="text-base font-semibold leading-7 text-emerald-600">{{ $t('home.allInOneNFT') }}</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-neutral-900 dark:text-neutral-50 sm:text-4xl">
          {{ $t('home.embraceNFTs') }}
        </p>
        <p class="mt-6 text-lg leading-8 text-neutral-600 dark:text-neutral-300">
          {{ $t('home.experiencePower') }}
        </p>
        <dl class="mt-10 max-w-xl space-y-8 text-base leading-7 text-neutral-600 dark:text-neutral-300 lg:max-w-none">
          <div v-for="feature in features" :key="feature.name" class="relative pl-9">
            <NuxtLink :to="feature.to">
              <dt class="inline font-semibold text-neutral-900 dark:text-neutral-50">
                <component
                  :is="feature.icon"
                  class="absolute left-1 top-1 h-5 w-5 text-emerald-600"
                  aria-hidden="true"
                />
                {{ $t(`home.${feature.name}`) }}
              </dt>
              {{ ' ' }}
              <dd class="inline">{{ $t(`home.${feature.description}`) }}</dd>
            </NuxtLink>
          </div>
        </dl>
      </div>
    </div>
    <div class="flex h-full items-center sm:px-6 lg:px-0">
      <div
        class="relative isolate overflow-hidden bg-emerald-800 px-6 pt-8 dark:bg-emerald-600 sm:mx-auto sm:max-w-2xl sm:rounded-3xl sm:pl-8 sm:pr-0 sm:pt-8 lg:mx-0 lg:max-w-none"
      >
        <div
          class="absolute -inset-y-px -left-3 -z-10 w-full origin-bottom-left skew-x-[-30deg] bg-emerald-100 opacity-20 ring-1 ring-inset ring-white"
          aria-hidden="true"
        />
        <div class="mx-auto max-w-2xl sm:mx-0 sm:max-w-none">
          <img
            src="/mintgarden_studio_screenshot.webp"
            alt="MintGarden Studio screenshot"
            width="2432"
            height="1442"
            class="-mb-12 w-[57rem] max-w-none"
          />
        </div>
        <div
          class="pointer-events-none absolute inset-0 ring-1 ring-inset ring-black/10 sm:rounded-3xl"
          aria-hidden="true"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { SparklesIcon, UserGroupIcon, GlobeIcon } from '@heroicons/vue/outline';

const features = [
  {
    name: 'createAndMint',
    description: 'mintDescription',
    icon: SparklesIcon,
    to: '/mint',
  },
  {
    name: 'engageCommunity',
    description: 'communityDescription',
    icon: UserGroupIcon,
    to: 'https://docs.mintgarden.io/discord',
  },
  {
    name: 'featureExploreCollections',
    description: 'collectionsDescription',
    icon: GlobeIcon,
    to: '/collections',
  },
];
</script>
