<script setup lang="ts"></script>
<template>
  <div class="w-full">
    <HomepageCollectionTopTable
      :interval-in-days="'7'"
      :limit="10"
      :exclude-sus="true"
      :hide-num-items="true"
      :hide-num-trades="true"
    />
  </div>
</template>
